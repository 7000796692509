import React from 'react'
import {
  Container,
  DivBG,
  Section,
} from '@components'
import { MhealthEKGAppImage } from '../../../components/site/landings/images'
import { LandingPageLayout } from '../../../components/site/layout/landing-page-layout'
import { OfferForm } from '../../../components/site/offer'

const AllThatWeLetInPage = ({ location }) => {
  return (
    <LandingPageLayout pathname={location.pathname}>
      <DivBG
        className="w-full h-full"
        bgImage={MhealthEKGAppImage}
        bgClassName="h-full"
      >
        <div className="flex items-stretch items-center justify-center h-full bg-white bg-opacity-70">
          <Section className="h-full">
            <Container className="h-full container-sm">
              <div className="flex flex-col items-center justify-center h-full my-auto">
                <h1 className="m-0 mt-8 text-5xl text-center text-gray-700">
                  All That We Let In
                </h1>
                <p className="m-0 mt-2 text-2xl text-center">
                  mHealth Apps and APIs Are Easy to Hack
                </p>
                <p className="m-0 mt-6 text-xl text-center">
                  We tested 30 mobile healthcare apps, and every one displayed
                  API vulnerabilities that exposed personal healthcare data.
                </p>
                <p className="m-0 mt-2 text-xl text-center">
                  Learn in detail how they were hacked and what you can do to
                  prevent it from happening to your app.
                </p>
                <p className="text-lg text-center">
                  The full report—authored by Alissa Knight of Knight Ink—is
                  available now.
                </p>
                <OfferForm className="bg-transparent" offer="mhealth_report" />
              </div>
            </Container>
          </Section>
        </div>
      </DivBG>
    </LandingPageLayout>
  )
}

export default AllThatWeLetInPage
